<template>
    <div :class="`${isMobile ? 'container-banner-mobile' : 'container-banner'}`">
        <div :class="`${isMobile ? 'col-11 mx-auto p-0' : 'col-10 mx-auto p-0'}`">
            <span @click="goBack" :class="`${isMobile ? 'container-back-mobile' : 'container-back'}`">
                <span class="material-icons-outlined iconBack">chevron_left</span> 
                Voltar aos planos
            </span>
        </div>

        <div :class="`${isMobile ? 'container-plano-mobile col-11 mx-auto' : 'container-plano col-10 mx-auto'}`">
            <img :class="`${isMobile ? 'img-mobile' : 'img'}`" :src="plan.foto_plano" alt="" />
            
            <div :class="`${isMobile ? 'plano-mobile' : 'plano'}`">
                <h1 :class="`${isMobile ? 'plan_name-mobile' : 'plan_name'}`" >{{ plan.name }}</h1>
                <!-- <span :class="`${isMobile ? 'plan_carencia-mobile' : 'plan_carencia'}`" >
                    <span class="material-icons iconCheck">check_circle</span> 
                    {{
                        plan.carencia
                            ? `Carência mínima de ${plan.carencia} ${
                                plan.carencia > 1 ? "dias" : "dia"
                                }`
                            : "Carência zero"
                    }}
                </span> -->
                <div v-if="!isPessoaJuridicaActive" class="container-paymentType">
                    <div class="plan_valor">
                        <label class="type">
                            No cartão
                            <span v-if="plan.carencia && plan.carencia != 0" class="carenciaTituloCartao">com carência</span>
                            <span v-else class="carenciaTituloCartao">sem carência</span>
                        </label>
                        <div class="valor">
                            <span class="cifrao">R$</span>
                            <span class="number">{{ (plan.price / 100).toFixed(2).replace(".", ",") }}</span>
                            <span class="mes">/mês</span>
                        </div>
                        <div class="taxa">
                            <span>
                                Taxa de Adesão -
                                <strong>
                                    {{  
                                        plan.setup_fee == 0
                                            ? "Gratuita"
                                            : "R$ " + (plan.setup_fee / 100).toFixed(2).replace(".", ",")
                                    }}
                                </strong>
                            </span>
                            <div v-if="plan.carencia && plan.carencia != 0">
                                Carência mínima - 
                                <strong>{{`${plan.carencia} ${plan.carencia > 1 ? "dias" : "dia"}`}}</strong>
                            </div>
                            <div v-else>Carência - <strong>zero</strong></div>
                        </div>
                    </div>
                    <div class="plan_valor" v-if="plan.valor_boleto">
                        <label class="type">
                            No Boleto
                            <span v-if="plan.carencia_boleto && plan.carencia_boleto != 0" class="carenciaTitulo">com carência</span>
                            <span v-else class="carenciaTitulo">sem carência</span>
                        </label>
                        <div class="valor">
                            <span class="cifrao">R$</span>
                            <span class="number">{{  `${(plan.valor_boleto / 100).toFixed(2).replace(".", ",")}`  }}</span>
                            <span class="mes">/mês</span>
                        </div>

                        <div class="taxa">
                            <span>
                                Taxa de Adesão -
                                <strong>
                                    {{
                                        plan.setup_fee == 0
                                            ? "Gratuita"
                                            : "R$ " + (plan.setup_fee / 100).toFixed(2).replace(".", ",")
                                    }}
                                </strong>
                            </span>
                            <div v-if="plan.carencia_boleto && plan.carencia_boleto != 0">
                                Carência mínima -  
                                <strong>{{`${plan.carencia_boleto} ${plan.carencia_boleto > 1 ? "dias" : "dia"}`}}</strong>
                            </div>
                            <div v-else>Carência - <strong>zero</strong></div>
                        </div>
                    </div>
                </div>
            </div>

            <div :class="`${isMobile ? 'uniodonto-mobile' : 'uniodonto'}`">
                <div :class="`${isMobile ? 'text-img-mobile' : 'text-img'}`">
                    <img :class="`${isMobile ? 'img-logo-mobile' : 'img-logo'}`" :src="operadora.logo_src" alt="Logotipo Uniodonto" />
                </div>
                <form v-if="isPessoaJuridicaActive" @submit.prevent="submitFormEmpresa" class="form" >
                    <div class="inputContainer">
                            <label>Nome:</label>
                            <Input
                                type="default"
                                id="nome"
                                :className="'input-sidebar-minhaArea'"
                                placeholder="Nome"
                                v-model="Nome"
                            />
                        </div>
                        <div class="inputContainer">
                            <label>CNPJ da empresa:</label>
                            <Input
                                type="default"
                                id="cnpj"
                                :className="'input-sidebar-minhaArea'"
                                placeholder="CNPJ"
                                v-model="CNPJ"
                            />
                        </div>
                        <div class="inputContainer">
                            <label>Telefone:</label>
                            <Input
                                type="default"
                                id="tel"
                                :className="'input-sidebar-minhaArea'"
                                placeholder="Telefone"
                                v-model="Telefone"
                            />
                        </div>
                        <div class="inputContainer">
                            <label>Email:</label>
                            <Input
                                type="default"
                                id="email"
                                :className="'input-sidebar-minhaArea'"
                                placeholder="Email"
                                v-model="Email"
                            />
                        </div>
                        <div class="inputContainer">
                            <label>Quantidade de funcionários:</label>
                            <Input
                                id="qtd"
                                type="number"
                                :className="'input-sidebar-minhaArea'"
                                placeholder="Quantidade"
                                v-model.number="quantidade"
                                min="1"
                                @input="verifca"
                            />
                        </div>
                        <div class="container-paymentType" v-if="objeto">
                            <div class="plan_valor">
                                <label class="type">
                                    No cartão
                                    <span  class="carenciaTituloCartao">com carência</span>
                                    <!-- <span v-else class="carenciaTituloCartao">sem carência</span> -->
                                </label>
                                <div class="valor">
                                    <span class="cifrao">R$</span>
                                    <span class="number">{{ (objeto.price / 100).toFixed(2).replace(".", ",") }}</span>
                                    <span class="mes">/mês</span>
                                </div>
                                <div class="taxa">
                                    <span>
                                        Taxa de Adesão -
                                        <strong>
                                            {{  
                                                plan.setup_fee == 0
                                                    ? "Gratuita"
                                                    : "R$ " + (objeto.setup_fee / 100).toFixed(2).replace(".", ",")
                                            }}
                                        </strong>
                                    </span>
                                    <div v-if="objeto.carencia && objeto.carencia != 0">
                                        Carência mínima - 
                                        <strong>{{`${objeto.carencia} ${objeto.carencia > 1 ? "dias" : "dia"}`}}</strong>
                                    </div>
                                    <div v-else>Carência - <strong>zero</strong></div>
                                </div>
                            </div>
                            <div class="plan_valor" v-if="objeto.valor_boleto">
                                <label class="type">
                                    No Boleto
                                    <span v-if="objeto.carencia_boleto && objeto.carencia_boleto != 0" class="carenciaTitulo">com carência</span>
                                    <span v-else class="carenciaTitulo">sem carência</span>
                                </label>
                                <div class="valor">
                                    <span class="cifrao">R$</span>
                                    <span class="number">{{  `${(objeto.valor_boleto / 100).toFixed(2).replace(".", ",")}`  }}</span>
                                    <span class="mes">/mês</span>
                                </div>

                                <div class="taxa">
                                    <span>
                                        Taxa de Adesão -
                                        <strong>
                                            {{
                                                plan.setup_fee == 0
                                                    ? "Gratuita"
                                                    : "R$ " + (objeto.setup_fee / 100).toFixed(2).replace(".", ",")
                                            }}
                                        </strong>
                                    </span>
                                    <div v-if="objeto.carencia_boleto && objeto.carencia_boleto != 0">
                                        Carência mínima -  
                                        <strong>{{`${objeto.carencia_boleto} ${objeto.carencia_boleto > 1 ? "dias" : "dia"}`}}</strong>
                                    </div>
                                    <div v-else>Carência - <strong>zero</strong></div>
                                </div>
                            </div>
                        </div>
                        <Button
                    type="detalhesPlano-adicionar" 
                    text="Enviar contato"  
                    :click="() => submitFormEmpresa()"
                />
                </form>

                <Button
                    v-else
                    type="detalhesPlano-adicionar" 
                    text="Adicionar ao Carrinho"  
                    :click="() => shoppingCar.add(plan)"
                />
                    
                <div class="container-documentacao">

                    <Button 
                        type="detalhesPlano-documentacao" 
                        text="Documentação e contrato"  
                        :click="showDocs"
                        :hasItems="hasDocs"
                    />

                    <div 
                        :class="`${isMobile ? 'documentacao-compra-mobile animate__animated animate__fadeInDown' : 'documentacao-compra animate__animated animate__fadeInDown'}`" 
                        v-if="hasDocs"
                    >
                        <ul>
                            <li v-for="arquivo in arquivos" :key="arquivo.id">
                                <a :href="arquivo.path" target="_blank">{{  arquivo.nome  }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { inject, ref } from 'vue';
import Button from '../Elements/Button.vue';
import { useRoute, useRouter } from 'vue-router';
import { createEventosSite } from '../../services/util';
import axios from "axios";

export default {
    name: "BannerPlano",
    components: {
        Button
    },
    props: {
        plan: Object,
        arquivos: Array,
        operadora: Object,
        isMobile: Boolean,
        pessoa_juridica:Array,
        tipo_plano:String
    },
    data() {
        return {
            quantidade: 1,
            CNPJ:'',
            Email:'',
            Telefone:'',
            Nome:'',
            objeto:null,
            FaixaPriceShow:null,
        };
    },
    mounted() {
        console.log('Props Banner', this.plan, this.arquivos, this.operadora, this.isMobile);
    },
    computed: {
        isPessoaJuridicaActive() {
            console.log( "Is pessoa juridica",this.pessoa_juridica)
            return (
/*                 this.pessoa_juridica &&
                this.pessoa_juridica.some((item) => item.active === "1")  */
                this.tipo_plano==="1"
            );
        },
        minValue() {
            const activeItem = this.pessoa_juridica?.find((item) => item.active === "1");
            return activeItem ? Number(activeItem.min) : 1;
        },
        maxValue() {
            const activeItem = this.pessoa_juridica?.find((item) => item.active === "1");
            return activeItem ? Number(activeItem.max) : 100;
        },
        PosArray() {
            console.log("PosArray triggered, quantidade:", this.pessoa_juridica);
            return this.pessoa_juridica.find(
            (item) => item.min <= this.quantidade && item.max >= this.quantidade
            );
        },
        reactivePessoaJuridica() {
    return [...this.pessoa_juridica];
  },
    },
    watch: {
        quantidade(newVal) {
            console.log('Quantidade changed:', newVal);
            this.pessoa_juridica.forEach(faixa => {
                if(newVal>=faixa.min&&newVal<=faixa.max){
                    this.FaixaPriceShow=faixa.price
                }
            });
        },
    },
    methods: {
        submitForm() {
            let obj = {
                quantidade: this.quantidade,
                CNPJ:document.getElementById('cnpj').value,
                Email:document.getElementById('email').value,
                Telefone:document.getElementById('tel').value,
                Nome:document.getElementById('nome').value,
            }
            console.log(obj);
        },
        submitFormEmpresa() {
            const cnpj = document.getElementById('cnpj').value.trim();
            const telefone = document.getElementById('tel').value.trim();
            const email = document.getElementById('email').value.trim();
            const qtd_funcionarios = this.quantidade;
            const nome = document.getElementById('nome').value.trim();
            if (!cnpj) {
                alert('Por favor, preencha o CNPJ.');
                return;
            }
            if (!telefone) {
                alert('Por favor, preencha o telefone.');
                return;
            }
            if (!email) {
                alert('Por favor, preencha o e-mail.');
                return;
            }
            if (!nome) {
                alert('Por favor, preencha o nome.');
                return;
            }
            if (qtd_funcionarios <= 0 || qtd_funcionarios > this.objeto.max) {
                alert('Quantidade de funcionarios invalida');
                return;
            }

            let obj = {
                cnpj,
                plans_id: this.plan.id,
                telefone,
                email,
                qtd_funcionarios,
                nome,
            }
            console.log('submitFormEmpresa',obj);
            try {
                axios.post('https://api.comprodonto.com.br/api/empresas',obj)
                alert('Contato enviado!');
            } catch (error) {
                console.log('error submit',error)
            }
            
        },
        updateValue(event) {
            this.$emit('input', event.target.value); 
        },
        verifca(){
            this.quantidade=document.getElementById('qtd').value
            console.log('verifica enter 1', this.pessoa_juridica, this.quantidade)
            this.objeto=this.pessoa_juridica.find(
                (item) => item.min <= this.quantidade && item.max >= this.quantidade
            );
            console.log('verifica enter', this.objeto)
        }
    },
    setup() {
        const shoppingCar = inject('shoppingCar');
        const router = useRouter();
        const route = useRoute();
        const hasDocs = ref(false);

        const showDocs = () => {
            if (!hasDocs.value) createEventosSite("CLICK", "documentação_contrato");
            hasDocs.value = !hasDocs.value;
        };

        const goBack = () => {
            const arrayURL = route.fullPath.split("/detalhes");
            const newURL = arrayURL[0].replace(/uniodonto/i, 'planos')
            router.push(newURL);
        };

        return { hasDocs, showDocs, goBack, shoppingCar }
    }
}
</script>

<style scoped>
    .container-banner-mobile{
        background-color: #af104c;
        padding: 6.6rem 0 2.5rem 0;
        user-select: none;
    }
    .container-banner{
        background-color: #af104c;
        padding: 8.5rem 0 7rem 0;
        user-select: none;
    }

    .img-logo {
    width: 150px;
    margin-left: 35%;
    }
    .img-logo-mobile {
    width: 107px;
    margin-left: 1%;
    }

    .container-back-mobile{
        color: #00fff5;
        font-size: .9375rem;
        font-weight: 600;
        margin-bottom: .8rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        width: 12rem;
    }
    .container-back{
        color: #00fff5;
        font-size: .9375rem;
        font-weight: 600;
        margin-bottom: 1.5rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        width: 12rem;
    }

    .iconBack{
        position: relative;
        left: -.5rem;
    }

    .container-plano-mobile{
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        padding: 0;
    }
    .container-plano{
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #fff;
        padding: 1rem;
    }

    .img-mobile{
        width: 100%;
        min-height: 14rem;
    }

    .img{
        width: 28%;
    }

    .plano-mobile{
        width: 65%;
    }
    .plano{
        width: 35%;
        display: flex;
        flex-flow: column;
    }

    .plan_name-mobile{
        color: #000000;
        font-size: 1.25rem;
        font-weight: 700;
        margin-top: 1rem;
        text-align: center;
    }
    .plan_name{
        color: #000000;
        font-size: 1.5rem;
        font-weight: 700;
    }

    .plan_valor{
        border: solid 1px rgb(211, 211, 211);
        padding: .8rem 1.2rem;
        position: relative;
        margin: 1.5rem .25rem 0 .25rem;
        max-width: 11.5rem;
    }

    .type{
        font-weight: bold;
        font-size: .875rem;
        height: 2rem;
        padding: 0 .625rem;
        background-color: white;
        position: absolute;
        top: -1rem;
    }

    .valor{
        margin-top: .6rem;
    }

    .cifrao{
        color: #c90c53;
        font-size: 1.0625rem;
        font-weight: 700;
        text-transform: uppercase;
        margin-right: .5rem;
    }

    .number{
        color: #c90c53;
        font-size: 1.875rem;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 1.625rem;
        margin-right: .3125rem;
    }

    .mes{
        color: #747474;
        font-size: .75rem;
        font-weight: 500;
        line-height: .875rem;
        margin-left: 0;
    }

    .taxa{
        margin-top: .4rem;
        font-size: .625rem;
        color: #747474;
        font-weight: 500;
    }
    .taxa strong{
        font-weight: 700;
        color: #000;
    }

    .plan_carencia-mobile{
        display: flex;
        align-items: center;
        justify-content: center;
        color: #c90c53;
        font-size: .8rem;
        font-weight: 600;
        letter-spacing: -0.16px;
        margin: 0;
        text-align: center;
    }
    .plan_carencia{
        display: flex;
        align-items: center;
        color: #c90c53;
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: -0.16px;
        margin: .6rem 0;
    }
    .plan_carencia-mobile .iconCheck{
        font-size: 1rem;
        margin-right: .3rem;
    }
    .plan_carencia .iconCheck{
        font-size: 1.25rem;
        margin-right: .6rem;
    }

    .uniodonto-mobile{
        width: 100%;
        text-align: center;
        margin-top: .5rem;
    }
    .uniodonto{
        width: 30%;
    }

    .text-img-mobile span{
        display: block;
        color: #747474;
        font-size: .8125rem;
        font-weight: 500;
        display: block;
        margin-top: .3rem;
        margin-bottom: .5rem;
    }
    .text-img span{
        display: block;
        color: #747474;
        font-size: .8125rem;
        font-weight: 500;
        display: block;
        margin-top: .625rem;
    }

    .container-documentacao{
        position: relative;
    }

    .documentacao-compra-mobile{
        background-color: #fff;
        position: absolute;
        top: 3.45rem;
        left: 0;
        width: 100%;
        overflow: hidden;
        z-index: 10;
        box-shadow: 0px 15px 15px -8px rgb(99, 99, 99);
    }
    .documentacao-compra-mobile ul li a{
        color: #000;
        font-size: .875rem;
        font-weight: 400;
        margin-bottom: .75rem;
        margin-left: .75rem;
        display: block;
        text-decoration: none;
        text-align: left;
    }
    .documentacao-compra{
        background-color: #fff;
        position: absolute;
        top: 4.25rem;
        left: 0;
        width: 100%;
        overflow: hidden;
        z-index: 10;
        box-shadow: 0px 15px 15px -8px rgb(99, 99, 99);
    }
    .documentacao-compra ul li a{
        color: #000;
        font-size: .875rem;
        font-weight: 400;
        margin-bottom: .75rem;
        display: block;
        text-decoration: none;
    }

    .container-paymentType{
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
    }

    .carenciaTitulo{
        display: block;
        position: relative;
        top: -.55rem;
    }
    .carenciaTituloCartao{
        margin-top: .4rem;
        font-size: .625rem;
        color: #747474;
        font-weight: 500;
        display: block;
        position: relative;
        top: -.55rem;
    }

    @media screen and (min-width: 800px) {
        .container-paymentType{
            display: flex;
            flex-flow: row wrap;
            justify-content: flex-start;
        }
    }
    .inputContainer{
        position: relative;
        margin: 1.25rem 0;
    }

    .inputContainer label{
        position: absolute;
        top: -.5rem;
        left: 1rem;
        background-color: #fff;
        z-index: 10;
        padding: 0 .3rem;
        color: #4b4b4b;
        font-size: .75rem;
        font-weight: 500;
        line-height: .9675rem;
    }

    .inputContainer:hover > label{
        color: #ff96db;
    }
    input[type="number"] {
        width: 75%; 
    }
    input[type="default"] {
        width: 75%; 
    }
</style>