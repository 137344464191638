<template>
  <div class="container-fluid">
    <div class="row">
      <loading v-model:active="store.getters.loading"  name="loading" background-color="#000" color="#C90C53"/>
      <alertbox />
      <modal />
      <modal-terms-and-politcs />
      <modal-login />
      <modal-titular />
      <div class="hidden col-12 p-0">
        <cookies :accept="acceptCookies" />
        <sidebar :isMobile="isMobile" />
        <sidebar-dados-cartao :isMobile="isMobile" />
        <sidebar-dados-titular :isMobile="isMobile" />
        <sidebar-senha :isMobile="isMobile" />
        <shopping-car />
        <router-view />
        <a
          :href="`https://api.whatsapp.com/send?phone=${phone}&text=Ol%C3%A1,%20gostaria%20de%20saber%20mais%20sobre%20os%20planos%20odontol%C3%B3gicos.`"
          target="_blank"
          :class="`${(route.path.includes('uniodonto') || route.path.includes('finalizar-compra')) && isMobile ? 'link-whats custom' : 'link-whats'}`"
        >
          <svg style="width:40px;height:40px;color: #fff;position: relative; left: .05rem;" viewBox="0 0 24 24">
            <path fill="currentColor" d="M12.04 2C6.58 2 2.13 6.45 2.13 11.91C2.13 13.66 2.59 15.36 3.45 16.86L2.05 22L7.3 20.62C8.75 21.41 10.38 21.83 12.04 21.83C17.5 21.83 21.95 17.38 21.95 11.92C21.95 9.27 20.92 6.78 19.05 4.91C17.18 3.03 14.69 2 12.04 2M12.05 3.67C14.25 3.67 16.31 4.53 17.87 6.09C19.42 7.65 20.28 9.72 20.28 11.92C20.28 16.46 16.58 20.15 12.04 20.15C10.56 20.15 9.11 19.76 7.85 19L7.55 18.83L4.43 19.65L5.26 16.61L5.06 16.29C4.24 15 3.8 13.47 3.8 11.91C3.81 7.37 7.5 3.67 12.05 3.67M8.53 7.33C8.37 7.33 8.1 7.39 7.87 7.64C7.65 7.89 7 8.5 7 9.71C7 10.93 7.89 12.1 8 12.27C8.14 12.44 9.76 14.94 12.25 16C12.84 16.27 13.3 16.42 13.66 16.53C14.25 16.72 14.79 16.69 15.22 16.63C15.7 16.56 16.68 16.03 16.89 15.45C17.1 14.87 17.1 14.38 17.04 14.27C16.97 14.17 16.81 14.11 16.56 14C16.31 13.86 15.09 13.26 14.87 13.18C14.64 13.1 14.5 13.06 14.31 13.3C14.15 13.55 13.67 14.11 13.53 14.27C13.38 14.44 13.24 14.46 13 14.34C12.74 14.21 11.94 13.95 11 13.11C10.26 12.45 9.77 11.64 9.62 11.39C9.5 11.15 9.61 11 9.73 10.89C9.84 10.78 10 10.6 10.1 10.45C10.23 10.31 10.27 10.2 10.35 10.04C10.43 9.87 10.39 9.73 10.33 9.61C10.27 9.5 9.77 8.26 9.56 7.77C9.36 7.29 9.16 7.35 9 7.34C8.86 7.34 8.7 7.33 8.53 7.33Z" />
          </svg>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import AlertBox from './components/Elements/AlertBox.vue';
import Modal from './components/Elements/Modal.vue';
import { useStore } from 'vuex';
import ModalTermsAndPolitics from './components/Elements/ModalTermsAndPolitics.vue';
import ModalLogin from './components/Elements/ModalLogin.vue';
import ModalTitular from './components/FinalizarCompra/Dependentes/ModalTitular.vue';
import Sidebar from './components/Elements/Sidebar.vue';
import Cookies from './components/Elements/Cookies.vue';
import ShoppingCar from './components/Carrinho/ShoppingCar.vue';
import { getCookie } from './services/util';
import SidebarDadosCartao from './components/MinhaArea/MeusDados/SidebarDadosCartao.vue';
import SidebarDadosTitular from './components/MinhaArea/MeusDados/SidebarDadosTitular.vue';
import SidebarSenha from './components/MinhaArea/MeusDados/SidebarSenha.vue';
import { inject, ref,onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { eventBus } from './services/eventbus';

export default {
  name: 'App',
  components:{
    'alertbox': AlertBox,
    'loading': Loading,
    'modal': Modal,
    'modalTermsAndPolitcs': ModalTermsAndPolitics,
    'modalLogin': ModalLogin,
    'modalTitular': ModalTitular,
    'sidebar': Sidebar,
    'shoppingCar': ShoppingCar,
    'cookies': Cookies,
    'sidebarDadosCartao': SidebarDadosCartao,
    'sidebarDadosTitular': SidebarDadosTitular,
    'sidebarSenha': SidebarSenha,
  },
  data() {
    return {
      acceptCookies: "",
    }
  },
  created() {
    const response = getCookie('acceptCookies_comprodonto');
    this.acceptCookies = response !== "" ? true : false;
    
    this.isMobile = window.innerWidth < 800 ?  true : false;
    window.addEventListener('resize', () => {
      this.isMobile = window.innerWidth < 800 ?  true : false;
    })

    const shoppingCar = inject("shoppingCar");
    shoppingCar.start();

  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const isMobile = ref(false);
    const phone = ref('5535933007935');

    onMounted(() => {
      eventBus.on('whatsapp', () => {
        console.log("WhatsApp event triggered!");
        const UniodontoPhone = JSON.parse(localStorage.getItem('operadora'))
        phone.value = UniodontoPhone.telefone.replace(/[\s()-]/g, '')
      });
      if(localStorage.getItem('operadora')){
                const UniodontoPhone = JSON.parse(localStorage.getItem('operadora'))
                phone.value = UniodontoPhone.telefone.replace(/[\s()-]/g, '')
      }
    });
    return { store, isMobile, route, phone }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');
html {
  scroll-behavior: smooth;
  box-sizing: border-box;
}
body {
  margin: 0;
}
#app {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgb(179, 179, 179) transparent;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
  scroll-behavior: smooth !important;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(179, 179, 179);
  border-radius: 20px !important;
}
.link-whats{
  position: fixed;
  bottom: 1rem;
  right: 3%;
  z-index: 30;
  box-shadow: 0 0 2px .8px rgb(204, 204, 204);
  border-radius: 50%;
  background-color: #51CD5F;
  padding: .5rem;
}

.custom{
  bottom: 5.5rem;
  right: 2%;
  padding: .3rem;
}

.hidden{
  overflow: hidden;
}
</style>
