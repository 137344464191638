<template>
  <Navbar type="default" :background="true" :isMobile="isMobile" />
  <section
    :class="`${isMobile ? 'planos col-11 mx-auto' : 'planos col-10 mx-auto'}`"
  >
    <div class="container">
      <div v-if="details.foto_capa" class="foto-container">
        <img
          v-if="isMobile"
          class="foto-capa"
          :src="details.foto_capa_mobile"
        />
        <img v-else class="foto-capa" :src="details.foto_capa" />
      </div>
      <div class="plano-text">
        <h1>Planos Uniodonto para sua Região</h1>
        <span>
          <button class="material-icons iconCheck" @click="openModal">info</button>
        </span>
      </div>
      <div class="cards-planos">
        <template v-for="(plan, index) in plans" :key="index">
          <CardsPlanos :plan="plan" :details="details" @click="() => openDetail(plan)" />
        </template>
      </div>
    </div>
  </section>
  <Footer type="default" :isMobile="isMobile" />
  <ModalCidades ref="modalCidades" :cities="cities" @close="closeModal" />
</template>
<script>
import { inject, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import Navbar from "../components/Elements/Navbar.vue";
import CardsPlanos from "../components/Planos/CardsPlanos.vue";
import Footer from "../components/Elements/Footer.vue";
import { GET } from "../services/api";
import { useStore } from 'vuex';
import ModalCidades from "../components/Elements/ModalCidades.vue";
import { eventBus } from '../services/eventbus';

export default {
  name: "Planos",
  components: {
    Navbar,
    CardsPlanos,
    Footer,
    ModalCidades
  },
  created() {
    this.isMobile = window.innerWidth < 800 ? true : false;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth < 800 ? true : false;
    });
  },
  setup() {
    const loader = inject("loading");
    const alert = inject("alert");
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const isMobile = ref(false);
    const plans = ref([]);
    const details = ref({});
    const content = ref("");
    const cities = ref([])
    const showCities = ref(false)
    const modalCidades = ref(null); 
    
    const openDetail = (plan) => {
      router.push(`/uniodonto/${route.params.estado}/${route.params.cidade}/detalhes/planos/${plan.id}`);
    };
    
    const openModal = () => {
      if (modalCidades.value) {
        modalCidades.value.open();
      }
    };

    const closeModal = () => {
      if (modalCidades.value) {
        modalCidades.value.close();
      }
    };

    onMounted( async () => {
      loader.open();
      let id;
      try{
        const response = await GET(`operadoras/${route.params.estado}/${route.params.cidade}/detalhes`);
        plans.value = response.planos;
        details.value = response.detalhes;
        cities.value=response.detalhes.cidades
        localStorage.setItem('operadora',JSON.stringify(response.detalhes))
        eventBus.emit('whatsapp')
        id = response.detalhes.id;
        let tamanhoString, inicio, fim;
        if(details.value.foto_capa) {
          tamanhoString = details.value.foto_capa.length;
          inicio = details.value.foto_capa.substr(0, tamanhoString - 4);
          fim = details.value.foto_capa.substr(tamanhoString - 4, 4);
        }
        details.value.foto_capa_mobile = `${inicio}-mobile${fim}`;
      } catch (e) {
        alert.open("Erro", "Desculpe, ocorreu um erro ao buscar determinado plano!");
      }

      try {
        const marketing = await GET(`operadoras/${id}/marketing`);
        content.value = marketing.popup_texto;
      } catch (e) {
        alert.open("Erro", "Desculpe, ocorreu um erro ao buscar por marketing!");
      }
      loader.close();
    })

    return {
      isMobile,
      router,
      plans,
      openDetail,
      details,
      content,
      store,
      cities,
      showCities,
      openModal, 
      closeModal, 
      modalCidades, 
    };
  },
};
</script>
<style scoped>
.planos {
  padding-top: 93px;
  margin-bottom: 6rem;
  display: block;
  padding: 93px 0 0 0;
}
.container {
  width: 100%;
  margin: 0 auto;
  border: none;
  padding: 0;
}
.planos .container .plano-text {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 49px;
  margin-bottom: 61px;
  font: inherit;
  vertical-align: baseline;
}
.planos .container .plano-text h1 {
  color: #747474d7;
  margin-top:auto;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  margin-left: 0;
}

.planos .container .plano-text span {
  color: #c90c53;
  font-family: Poppins;
  margin-top:auto;
  padding-bottom: 6px;
  font-size: 18px;
  font-weight: 500;
  display: block;
  margin-left: 14px;
}
.planos .container .cards-planos {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
}
.planos .container .cards-planos .card {
  flex: 1 1 calc(33.333% - 1rem);
  margin: 0.5rem;
  max-width: calc(33.333% - 1rem);
  box-sizing: border-box;
}
.planos .container .cards-planos .card .container-text {
  padding-top: 28px;
  padding-left: 37px;
  padding-right: 37px;
  padding-bottom: 25px;
  border: 1px solid #edebeb;
  border-top: none;
}
.planos .container .cards-planos .card .container-text h3 {
  color: #000;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
  cursor: pointer;
}

.planos .container .cards-planos .card .container-text p {
  color: #747474;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 10px;
  cursor: pointer;
}
.planos .container .cards-planos .card .btn-planos {
  height: 65px;
  border-radius: 0 0 4px 4px;
  background-color: #edebeb;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 37px;
  cursor: pointer;
}
.planos .container .cards-planos .card .container-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  cursor: pointer;
}
.planos .container .cards-planos .card .btn-planos a {
  color: #c90c53;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.planos .container .cards-planos .card .btn-planos img {
  margin-left: 5px;
}
.planos .container .cards-planos .card .container-text .container-valor {
  display: flex;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
}
.planos .container .cards-planos .card .container-text .container-valor p {
  color: #c90c53;
  font-family: Poppins;
  font-weight: 600;
  letter-spacing: -0.14px;
  font-size: 20px;
  margin-bottom: 0;
  margin-left: 9px;
}
.iconCheck{
  color: #c90c53;
  font-size: 1.25rem;
  user-select: none;
  background: none;
  border: none;
  cursor: pointer;
  outline: none; /* Remove outline */
}

.foto-container{
  display: flex;
  justify-content: center;
}

.foto-capa{
  margin-top: 1rem;
  width: 100%;
}

@media screen and (min-width: 400px){
  .foto-capa{
    width: auto;
  }
}

@media screen and (min-width: 800px){
  .container{
    width: 100%;
  }

  .planos .container .cards-planos .card {
    flex: 1 1 calc(33.333% - 1rem);
    margin: 0.5rem;
    max-width: calc(33.333% - 1rem);
  }
}

@media screen and (min-width: 3200px){
  .planos{
    min-height: calc(100vh - 310px);
  }
}
@media screen and (max-width: 800px) {
  .planos .container .cards-planos .card {
    flex: 1 1 100%;
    max-width: 100%;
  }
}

</style>
